import * as process from 'process'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CaptionsContainer } from 'src/shared/ui/components/captions-container'
import { Container, Heading } from 'ui'
import { useCheckSessionMutation } from '../../entities/session/model/hooks/use-check-session'
import { Session } from '../../entities/session/model/session'
import { SessionId } from '../../entities/session/ui/session-id'
import { useCaptionsSize } from '../../features/change-captions-font-size/model/captions-size-context'
import { useInterfaceLanguage } from '../../features/lokalization/provider'
import { Captions } from '../../features/speaker/components/captions'
import useSocket from '../../shared/api/socket'

interface AudienceProps {
  sessionId: string
}
export const Audience: FC<AudienceProps> = ({ sessionId }) => {
  const { t } = useTranslation('audience')
  const [captions, setCaptions] = useState('')
  const [language, setLanguage] = useState('')
  const [room, setRoom] = useState<Session>()
  const [error, setError] = useState(null)
  const [previousLanguage, setPreviousLanguage] = useState('')

  const { captionsSizeOption } = useCaptionsSize()

  const { interfaceLanguage } = useInterfaceLanguage()

  const { mutateAsync: checkSession } = useCheckSessionMutation()

  useEffect(() => {
    setLanguage(interfaceLanguage.value)
  }, [interfaceLanguage.value])

  const onCaption = (message) => {
    const data = JSON.parse(message.data)
    if (data['type'] === 'CAPTION') {
      setCaptions((captions) => captions + ' ' + data['text'])
    }
  }

  const socket = useSocket({
    url: `${process.env.SOCKET_URL}captions/${sessionId}/`,
    onMessage: onCaption,
    onClose: () => {},
  })

  useEffect(() => {
    const connectFunction = async (sessionId: string) => {
      try {
        const session = await checkSession(sessionId)
        await socket.connect()
        setRoom(session)
      } catch (e) {
        setError(e)
      }
    }
    connectFunction(sessionId!)
  }, [sessionId])

  useEffect(() => {
    if (!room) return
    if (previousLanguage != '') {
      socket.send(JSON.stringify({ type: 'UNSUBSCRIBE', language: previousLanguage }))
      setPreviousLanguage('')
    }

    if (language != '') {
      setCaptions('')
      setPreviousLanguage(language)
      console.log('subscribing')
      socket
        .send(JSON.stringify({ type: 'SUBSCRIBE', language: language }))
        .then(() => {
          console.log('subscribed')
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [language, room])

  if (error) {
    return <div>Error</div>
  }

  return (
    <Container display="flex" flexDirection="column" height="100%">
      <CaptionsContainer>
        {captions ? (
          <Captions captions={captions} captionsSizeOption={captionsSizeOption} />
        ) : (
          <Container display="flex" alignItems="center" flex={1}>
            <Heading
              variant="h2"
              textAlign="center"
              color="greyDark"
              margin="0 auto"
              as="p"
              maxWidth="20rem"
            >
              {t('textArea')}
            </Heading>
          </Container>
        )}
      </CaptionsContainer>
      <Container textAlign="center" pt="3.6rem">
        <SessionId variant="secondary" />
      </Container>
    </Container>
  )
}
