import { CreateSessionData } from '../../model/create-session'
import { Session } from '../../model/session'
import { CreateSessionPayload } from '../model/create-session'
import { checkSession } from '../session-api/check-session'
import { createSession } from '../session-api/create-session'
import { SessionRepository } from './session-repository'

export class SessionRepositoryImplementation implements SessionRepository {
  async createSession(payload: CreateSessionData): Promise<Session> {
    const createSessionPayload: CreateSessionPayload = {
      name: payload.name,
      languageCode: payload.outputLanguage,
    }
    const { data } = await createSession(createSessionPayload)
    return {
      id: data.roomId,
      name: payload.name,
      outputLanguage: payload.outputLanguage,
    }
  }
  async checkSession(sessionId: string): Promise<Session> {
    const { data } = await checkSession(sessionId)
    return {
      id: data.id,
      name: data.name,
      outputLanguage: data.languageCode,
    }
  }
}
