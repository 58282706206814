import React from 'react'
import styled from 'styled-components'
import { Container, Heading, Text } from 'ui'
import { useCaptionsSize } from '../../model/captions-size-context'

export const CaptionsSizePicker = () => {
  const { captionsSizeOption, setCaptionsSizeOption } = useCaptionsSize()

  return (
    <FontSizePickerContainer>
      <CaptionButton
        isChosen={captionsSizeOption === 0}
        disabled={captionsSizeOption === 0}
        onClick={() => {
          setCaptionsSizeOption(0)
        }}
      >
        <Text as="span" variant="smallTextMedium">
          A
        </Text>
      </CaptionButton>

      <CaptionButton
        isChosen={captionsSizeOption === 1}
        disabled={captionsSizeOption === 1}
        onClick={() => {
          setCaptionsSizeOption(1)
        }}
      >
        <Heading as="span" variant="h3">
          A
        </Heading>
      </CaptionButton>
    </FontSizePickerContainer>
  )
}

const FontSizePickerContainer = styled(Container)`
  padding: 0.4rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
`

const CaptionButton = styled.button<{ isChosen: boolean }>`
  width: 5.2rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  outline: none;
  background-color: ${({ theme, isChosen }) => (isChosen ? theme.colors.greyLight : 'transparent')};
  color: ${({ theme, isChosen }) => (isChosen ? theme.colors.black : theme.colors.greySuperDark)};
`
