import { toast } from 'react-toastify'

type MessageEvent = {
  data: any
  type: string
  target: WebSocket
}

type CloseEvent = {
  wasClean: boolean
  code: number
  reason: string
  target: WebSocket
}

type Options = {
  url: string
  onMessage: (event: MessageEvent) => void
  onClose: (event: CloseEvent) => void
}

let connection: WebSocket | null = null

const useSocket = ({ url, onMessage, onClose }: Options) => {
  const connect = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (connection !== null) {
        reject('already connected')
      }

      connection = new WebSocket(url)
      connection.addEventListener('open', () => resolve())
      connection.addEventListener('error', (e) => {
        toast.error(JSON.stringify(e))
        reject(e)
      })
      connection.addEventListener('message', (event) => {
        onMessage(event)
      })
      connection.addEventListener('close', (something) => {
        console.log('closed because', something)
        connection = null
        if (onClose) {
          onClose()
        }
      })
    })
  }

  const close = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (connection === null) {
        reject('Not connected')
      }

      connection.addEventListener('close', () => resolve())
      connection.close()
    })
  }

  const send = (data: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (connection === null) {
        reject('Not connected')
      }

      connection.send(data)
      resolve()
    })
  }

  return {
    connect,
    close,
    send,
  }
}

export default useSocket
