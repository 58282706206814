import * as process from 'process'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { toast } from 'react-toastify'
import { CaptionsContainer } from 'src/shared/ui/components/captions-container'
import { Container, Heading } from 'ui'
import { SessionId } from '../../entities/session/ui/session-id'
import { useInterfaceLanguage } from '../../features/lokalization/provider'
import { Captions } from '../../features/speaker/components/captions'
import useSocket from '../../shared/api/socket'
import { routes } from '../../shared/navigation/routes'
import { SpeakerControls } from '../../shared/ui/components/speaker-controls'

interface SpeakerProps {
  sessionId: string
}

export const Speaker: FC<SpeakerProps> = ({ sessionId }) => {
  const { t } = useTranslation('speaker')
  const { transcript, resetTranscript } = useSpeechRecognition()
  const [truncatedTranscript, setTruncatedTranscript] = useState('')
  const [connected, setConnected] = useState(false)
  const [handle, setHandle] = useState<NodeJS.Timeout | null>(null)

  const navigate = useNavigate()

  const { interfaceLanguage } = useInterfaceLanguage()

  const socket = useSocket({
    url: `${process.env.SOCKET_URL}stream/${sessionId}/`,
    onMessage: () => {},
    onClose: () => {
      SpeechRecognition.stopListening()
      setConnected(false)
    },
  })

  const transcriptRef = useRef('')
  const countRef = useRef(0)
  const sentText = useRef('')

  const sendTranscript = () => {
    const text = transcriptRef.current.replace(sentText.current, '')
    if (text.length > 0) {
      if (text.length > 20 || countRef.current > 2) {
        countRef.current = 0
        socket.send(text).then(() => {
          sentText.current = transcriptRef.current
        })
      }
    }
    countRef.current = countRef.current + 1
  }

  const startStop = async () => {
    try {
      if (!connected) {
        await socket.connect()
        await SpeechRecognition.startListening({
          language: interfaceLanguage.value,
          continuous: true,
        })
        setHandle(setInterval(sendTranscript, 1000))
        setConnected(true)
      } else {
        await SpeechRecognition.stopListening()
        await socket.close()
        if (handle) {
          clearInterval(handle)
        }
        setHandle(null)
        setConnected(false)
      }
    } catch (error) {
      console.error(error, 'Error in startStop')
      toast.error('Something went wrong')
    }
  }

  useEffect(() => {
    setTruncatedTranscript(transcript.split(' ').slice(-50).join(' '))
  }, [transcript])

  useEffect(() => {
    transcriptRef.current = transcript
  })

  return (
    <Container display="flex" flexDirection="column" height="100%">
      <CaptionsContainer>
        {truncatedTranscript ? (
          <Captions captions={transcript} />
        ) : (
          <Container display="flex" alignItems="center" height="100%">
            <Heading
              variant="h2"
              textAlign="center"
              color="greyDark"
              margin="0 auto"
              as="p"
              maxWidth="20rem"
            >
              {t('textArea')}
            </Heading>
          </Container>
        )}
      </CaptionsContainer>
      <Container display="flex" flexDirection="column" alignItems="center">
        <SpeakerControls
          resetTranscript={resetTranscript}
          connected={connected}
          startStop={startStop}
          invite={() => {
            navigate(`${routes.invite}/${sessionId}`)
          }}
          my={{ _: '3.2rem', tablet: ' 4rem' }}
        />
        <SessionId variant="secondary" />
      </Container>
    </Container>
  )
}
