import { useMutation, useQuery } from '@tanstack/react-query'
import { sessionService } from '../../api/service/session-service'

export const useCheckSessionQuery = (sessionId?: string) => {
  return useQuery(
    ['checkSession'],
    () => {
      return sessionService.checkSession(sessionId!)
    },
    {
      enabled: !!sessionId,
    }
  )
}

export const useCheckSessionMutation = () => {
  return useMutation({
    mutationFn: (sessionId: string) => {
      return sessionService.checkSession(sessionId)
    },
  })
}
