import React, { FC } from 'react'
import { SpaceProps } from 'styled-system'
import { Container, Heading, Text } from 'ui'

interface PageBigTitleProps extends SpaceProps {
  heading: string
  title: string
}

export const PageBigTitle: FC<PageBigTitleProps> = ({ heading, title, ...space }) => {
  return (
    <Container {...space}>
      <Heading
        variant="h1"
        textAlign="center"
        mb="1.6rem"
        fontSize={{ _: '3.2rem', tablet: '4.8rem' }}
      >
        {heading}
      </Heading>
      <Text textAlign="center">{title}</Text>
    </Container>
  )
}
