import React, { FC } from 'react'
import QRCode from 'react-qr-code'
import { SpaceProps } from 'styled-system'
import { Container } from 'ui'

interface QRProps extends SpaceProps {
  value: string
}
export const QR: FC<QRProps> = ({ value, ...spacing }) => {
  return (
    <Container
      padding="1.2rem"
      backgroundColor="white"
      maxWidth="24rem"
      borderRadius="1.2rem"
      margin="0 auto"
      {...spacing}
    >
      <QRCode style={{ height: 'auto', maxWidth: '100%', width: '100%' }} value={value} />
    </Container>
  )
}
