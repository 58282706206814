import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react'
import { CaptionsSizeOption } from './captions-size-option'

interface CaptionsSizeContext {
  captionsSizeOption: CaptionsSizeOption
  setCaptionsSizeOption: (captionsSizeOption: CaptionsSizeOption) => void
}

const CaptionsSizeContext = createContext<CaptionsSizeContext>({
  captionsSizeOption: 1,
  setCaptionsSizeOption: () => {},
})

export const useCaptionsSize = () => useContext(CaptionsSizeContext)

export const CaptionsSizeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [captionsSizeOption, setCaptionsSizeOption] = useState<CaptionsSizeOption>(1)
  return (
    <CaptionsSizeContext.Provider
      value={{
        captionsSizeOption,
        setCaptionsSizeOption,
      }}
    >
      {children}
    </CaptionsSizeContext.Provider>
  )
}
