import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, ChevronDownIcon, Container, Heading, ScrollContainer } from 'ui'
import { CaptionsSizeOption } from '../../../change-captions-font-size/model/captions-size-option'

interface CaptionsProps {
  captions: string
  captionsSizeOption?: CaptionsSizeOption
}

export const Captions: FC<CaptionsProps> = ({ captions, captionsSizeOption = 1 }) => {
  const [isAtBottom, setIsAtBottom] = useState(true)
  const bottomRef = useRef<HTMLDivElement>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        setIsAtBottom(entry.isIntersecting)
      },
      { threshold: 1.0 }
    )

    if (bottomRef.current) {
      observer.current.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current && observer.current) {
        observer.current.unobserve(bottomRef.current)
      }
    }
  }, [])

  return (
    <Container
      display="flex"
      alignItems="flex-end"
      padding="2.4rem"
      position="relative"
      height="100%"
    >
      <ScrollContainer
        height="100%"
        display="flex"
        flexDirection="column-reverse"
        position="relative"
      >
        <ScrollAnchor ref={bottomRef} />
        {captionsSizeOption === 1 && <Heading variant="h1">{captions}</Heading>}
        {captionsSizeOption === 0 && <Heading variant="h2">{captions}</Heading>}
      </ScrollContainer>

      {!isAtBottom && (
        <Button
          onClick={scrollToBottom}
          variant="secondary"
          circle
          position="absolute"
          right="2rem"
          bottom="2rem"
          width="4rem"
          height="4rem"
        >
          <ChevronDownIcon />
        </Button>
      )}
    </Container>
  )
}

const ScrollAnchor = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
  width: 100%;
`
