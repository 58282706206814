import { CreateSessionData } from '../../model/create-session'
import { Session } from '../../model/session'
import { SessionRepository } from '../repositories/session-repository'
import { SessionRepositoryImplementation } from '../repositories/session-repository-implementation'

export class SessionService {
  constructor(private sessionRepository: SessionRepository) {
    this.sessionRepository = sessionRepository
  }

  async createSession(data: CreateSessionData): Promise<Session> {
    return this.sessionRepository.createSession(data)
  }

  async checkSession(sessionId: string): Promise<Session> {
    return this.sessionRepository.checkSession(sessionId)
  }
}

export const sessionService = new SessionService(new SessionRepositoryImplementation())
