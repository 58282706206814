import React, { createContext, useContext, useEffect, useState } from 'react'
import { i18n } from 'src/i18n'

export const chatsInterfaceLanguageOptions: Language[] = [
  { value: 'en', label: 'English' },
  { value: 'th', label: 'ภาษาไทย' },
]

const findLanguageByValue = (value: string): Language | null =>
  chatsInterfaceLanguageOptions.find((language) => language.value === value) || null

interface InterfaceLanguageProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface Language {
  value: string
  label: string
}

export interface InterfaceLanguageStateDefinition {
  interfaceLanguage: Language
  interfaceLanguageOptions: Language[]
  changeInterfaceLanguage: (lang: string) => void
  removeInterfaceLanguage: VoidFunction
}

export const initData: InterfaceLanguageStateDefinition = {
  interfaceLanguage: chatsInterfaceLanguageOptions[0],
  interfaceLanguageOptions: chatsInterfaceLanguageOptions,
  changeInterfaceLanguage: () => null,
  removeInterfaceLanguage: () => null,
}

const InterfaceLanguageContext = createContext<InterfaceLanguageStateDefinition>(initData)

const InterfaceLanguageProvider = ({ children }: InterfaceLanguageProviderProps) => {
  const interfaceLanguageFromLocaleStorage = localStorage.getItem('i18nextLng')
  const detectedBrowserLanguage = navigator.language.split('-')[0] // en-US to en
  const initialLanguageValue = interfaceLanguageFromLocaleStorage || detectedBrowserLanguage || 'en'

  const initialLanguage =
    findLanguageByValue(initialLanguageValue) || chatsInterfaceLanguageOptions[0]

  const [interfaceLanguage, setInterfaceLanguage] = useState<Language>(initialLanguage)

  const changeInterfaceLanguage = (langValue: string) => {
    const selectedLanguage = findLanguageByValue(langValue)

    if (selectedLanguage) {
      setInterfaceLanguage(selectedLanguage)
      i18n.changeLanguage(langValue)
      localStorage.setItem('i18nextLng', langValue)
    }
  }

  const removeInterfaceLanguage = () => localStorage.removeItem('i18nextLng')

  useEffect(() => {
    if (!interfaceLanguageFromLocaleStorage) {
      changeInterfaceLanguage(detectedBrowserLanguage)
    }
  }, [interfaceLanguageFromLocaleStorage, detectedBrowserLanguage])

  return (
    <InterfaceLanguageContext.Provider
      value={{
        interfaceLanguage,
        changeInterfaceLanguage,
        removeInterfaceLanguage,
        interfaceLanguageOptions: chatsInterfaceLanguageOptions,
      }}
    >
      {children}
    </InterfaceLanguageContext.Provider>
  )
}
const useInterfaceLanguage = () => {
  const context = useContext(InterfaceLanguageContext)

  if (!context) {
    throw new Error('useInterfaceLanguage must be used within InterfaceLanguageProvider')
  }
  return useContext(InterfaceLanguageContext)
}

export { InterfaceLanguageProvider, InterfaceLanguageContext, useInterfaceLanguage }
