import { AddUserIcon } from 'flow/src/components/icons'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Button, Container, MicrophoneIcon, PauseIcon, ResetIcon } from 'ui'
import { SmallControlButton } from './small-control-button'

interface SpeakerControlsProps extends SpaceProps {
  resetTranscript: VoidFunction
  startStop: VoidFunction
  invite: VoidFunction
  connected: boolean
}

export const SpeakerControls: FC<SpeakerControlsProps> = ({
  resetTranscript,
  startStop,
  connected,
  invite,
  ...spacing
}) => {
  const { t } = useTranslation('speaker')

  return (
    <ControlsContainer {...spacing}>
      <SmallControlButton
        aria-label={t('hiddenLabels.restartSpeech')}
        onClick={() => {
          resetTranscript()
        }}
      >
        <ResetIcon />
      </SmallControlButton>
      <Button
        circle
        aria-label={t('hiddenLabels.microphone')}
        onClick={() => {
          startStop()
        }}
        width="8rem"
        height="8rem"
        type="button"
        variant="primary"
      >
        {connected ? <PauseIcon /> : <MicrophoneIcon />}
      </Button>
      <SmallControlButton aria-label={t('hiddenLabels.addToAudience')} onClick={invite}>
        <AddUserIcon />
      </SmallControlButton>
    </ControlsContainer>
  )
}

const ControlsContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
`
