import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export const apiInstance = axios.create({
  baseURL: process.env.API_URL,
})

apiInstance.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  if (config.data) {
    config.data = snakecaseKeys(config.data, { deep: true })
  }
  return config
})

apiInstance.interceptors.response.use(
  (response) => {
    const data = camelcaseKeys(response.data, { deep: true })
    return { ...response, data }
  },
  (error) => {
    if (error.response) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error)
  }
)
