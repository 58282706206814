import * as process from 'process'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PageBigTitle } from 'src/shared/ui/components/page-big-title'
import { Button, Container, Heading, ShareIcon, Text } from 'ui'
import { SessionId } from '../../entities/session/ui/session-id'
import { routes } from '../../shared/navigation/routes'
import { QR } from '../../shared/ui/components/qr-code'

export const InvitePage = () => {
  const { t } = useTranslation('invite')
  const { sessionId } = useParams()

  const showToastMessage = () => {
    toast.success(`${t('toastMessages.copied')}`)
  }

  return (
    <Container display="flex" height="100%" flexDirection="column">
      <Container maxWidth="48rem" m="0 auto" textAlign="center">
        <PageBigTitle heading={t('title')} title={t('subtitle')} mb="4rem" />

        <QR value={`${process.env.ENV}${routes.audience}/${sessionId}`} mt="4em" mb="2.4rem" />
        <SessionId />
        <CopyToClipboard
          text={`${process.env.ENV}${routes.audience}/${sessionId}`}
          onCopy={showToastMessage}
        >
          <Button maxWidth="34rem" width="100%" mt="4.8rem" preFix={<ShareIcon />}>
            {t('shareLink')}
          </Button>
        </CopyToClipboard>
      </Container>
    </Container>
  )
}
