import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, TextField } from 'ui'
import * as yup from 'yup'
import { routes } from '../../../../shared/navigation/routes'
import { useCheckSessionMutation } from '../../model/hooks/use-check-session'

interface InitialValues {
  sessionId: string
}

const initialValues: InitialValues = {
  sessionId: '',
}

const getValidationSchema = (errorMessages: { required: string; alphanumeric: string }) =>
  yup.object().shape({
    sessionId: yup
      .string()
      .required(errorMessages.required)
      .matches(/^[a-zA-Z0-9]*$/, errorMessages.alphanumeric),
  })

export const CheckSession = () => {
  const { t } = useTranslation(['entry'])

  const navigate = useNavigate()

  const { mutateAsync } = useCheckSessionMutation()

  const handleSubmit = async (values: InitialValues) => {
    try {
      const { id } = await mutateAsync(values.sessionId)
      navigate(`${routes.audience}/${id}`)
    } catch (e) {
      toast.error(`${t('errors.join')}`)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema({
        required: t('validation.required'),
        alphanumeric: t('validation.alphanumeric'),
      })}
    >
      {({ values, handleChange, handleBlur, isSubmitting, errors }) => {
        return (
          <Form>
            <TextField
              id="sessionId"
              name="sessionId"
              placeholder={t('enterSession.placeholder')}
              mb="1.6rem"
              value={values.sessionId}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.sessionId}
            />
            <Button variant="secondary" type="submit" width="100%" disabled={isSubmitting}>
              {t('enterSession.join')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
