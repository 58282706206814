import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, LanguageSelect, mediaQueries } from 'ui'
import { useInterfaceLanguage } from '../../../../../../features/lokalization/provider'
import { PoweredByNative } from '../../../../components/powered-by-native'

export const Header = () => {
  const { interfaceLanguage, interfaceLanguageOptions, changeInterfaceLanguage } =
    useInterfaceLanguage()
  const { t } = useTranslation('common')
  return (
    <HeaderContainer>
      <FlexContainer></FlexContainer>
      <FlexContainer>
        <PoweredByNative />
      </FlexContainer>
      <FlexContainer>
        <LanguageSelect
          variant="secondary"
          interfaceLanguage={interfaceLanguage}
          options={interfaceLanguageOptions}
          onClick={changeInterfaceLanguage}
          hiddenLabel={t('selectInterfaceLang')}
          hiddenLabelSelected={t('langSelected')}
        />
      </FlexContainer>
    </HeaderContainer>
  )
}

const FlexContainer = styled(Container)`
  flex: 1 1 auto;
  ${mediaQueries.tablet} {
    flex: 1 1 0;
  }
`

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  height: 5.2rem;

  ${FlexContainer}:first-of-type {
    display: none;
  }
  ${FlexContainer}:nth-of-type(2) {
    text-align: left;
  }
  ${FlexContainer}:nth-of-type(3) {
    text-align: right;
  }
  ${mediaQueries.tablet} {
    padding: 2rem 1.6rem;
    height: 8.4rem;
  }
  ${mediaQueries.desktop} {
    padding: 2rem 3.2rem;
  }
  ${mediaQueries.desktop} {
    ${FlexContainer}:first-of-type {
      display: block;
    }
    ${FlexContainer}:nth-of-type(2) {
      text-align: center;
    }
  }
`
