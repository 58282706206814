import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'ui'
import { useInterfaceLanguage } from '../../../../features/lokalization/provider'
import { routes } from '../../../../shared/navigation/routes'
import { useCreateSession } from '../../model/hooks/use-create-session'

export const CreateSessionButton = () => {
  const { t } = useTranslation(['entry'])
  const navigate = useNavigate()

  const { interfaceLanguage } = useInterfaceLanguage()

  const { mutateAsync, isLoading } = useCreateSession()

  return (
    <Button
      width="100%"
      mb="4rem"
      disabled={isLoading}
      onClick={async () => {
        try {
          const { id } = await mutateAsync({
            name: 'session',
            outputLanguage: interfaceLanguage.value,
          })
          navigate(`${routes.speaker}/${id}`)
        } catch (error) {
          console.log('error create session', error)
          toast.error('Error creating session, try again')
        }
      }}
    >
      {t('createSession')}
    </Button>
  )
}
