import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, DotsAnimation, Text } from 'ui'

export const PageLoading = () => {
  const { t } = useTranslation('common')
  return (
    <Container
      flex={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <DotsAnimation width={150} height={40} />
      <Text mt="1rem">{t('loggingIn')}</Text>
    </Container>
  )
}
