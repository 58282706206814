import { GlobalStyles } from '@styles/global-styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { Suspense } from 'react'
import { StrictMode } from 'react'
import { ModalProvider } from 'ui'
import { CaptionsSizeProvider } from './features/change-captions-font-size/model/captions-size-context'
import { InterfaceLanguageProvider } from './features/lokalization/provider'
import { Navigation } from './shared/navigation'
import { ToastContainer } from './shared/ui/components/toast-container'
import { ThemeProvider } from './shared/ui/theme/theme-provider'

export const App = () => {
  const queryClient = new QueryClient()
  return (
    <StrictMode>
      <Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <InterfaceLanguageProvider>
            <ThemeProvider>
              <CaptionsSizeProvider>
                <ToastContainer />
                <ModalProvider>
                  <GlobalStyles />
                  <Navigation />
                </ModalProvider>
              </CaptionsSizeProvider>
            </ThemeProvider>
          </InterfaceLanguageProvider>
        </QueryClientProvider>
      </Suspense>
    </StrictMode>
  )
}
