import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useCheckSessionQuery } from '../../entities/session/model/hooks/use-check-session'
import { useLoadingPageErrorToast } from '../../shared/hooks/use-loading-page-error-toast'
import { useLoadingState } from '../../shared/hooks/use-loading-page-state'
import { routes } from '../../shared/navigation/routes'
import { PageLoading } from '../../shared/ui/components/audience-loading'
import { Speaker } from '../../widgets/speaker'

export const SpeakerPage = () => {
  const { t } = useTranslation('common')

  const { sessionId } = useParams()

  const { data, isLoading, isError } = useCheckSessionQuery(sessionId)

  const isLogged = useLoadingState(isLoading, data, isError)

  useLoadingPageErrorToast(isError, t('tryAgain'))

  if (!isLogged) return <PageLoading />

  if (data && sessionId) {
    return <Speaker sessionId={sessionId} />
  }
  return <Navigate to={routes.entry} />
}
