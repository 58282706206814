import { useEffect } from 'react'
import { toast } from 'react-toastify'

export const useLoadingPageErrorToast = (isError: boolean, errorMessage: string) => {
  useEffect(() => {
    if (isError) {
      toast.error(errorMessage)
    }
  }, [isError])
}
