import { init as initSentry, BrowserTracing, Replay } from '@sentry/react'
import '@styles/fonts.css'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-toastify/dist/ReactToastify.css'
import { App } from './app'
import './i18n'

initSentry({
  dsn: process.env.SENTRY_DSN_FLOW,
  integrations: [new BrowserTracing(), new Replay()],
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.APP_ENVIRONMENT,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
