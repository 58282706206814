import React, { FC, PropsWithChildren } from 'react'
import { Button, ButtonInterface } from 'ui'

export const SmallControlButton: FC<PropsWithChildren<ButtonInterface>> = ({
  children,
  ...props
}) => {
  return (
    <Button circle width="4.8rem" height="4.8rem" type="button" variant="secondary" {...props}>
      {children}
    </Button>
  )
}
