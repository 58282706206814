import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { mediaQueries } from 'src/shared/ui/theme/media-queries'
import styled from 'styled-components'
import { Container, Button, ChevronLeftIcon, useModal, LanguageSelect } from 'ui'
import { CaptionsSizePicker } from '../../../../../../features/change-captions-font-size/ui/components/captions-size-picker'
import { LeaveSessionModal } from '../../../../../../features/leave-session/ui/modals/leave-session-modal'
import { useInterfaceLanguage } from '../../../../../../features/lokalization/provider'
import { routes } from '../../../../../navigation/routes'
import { PoweredByNative } from '../../../../components/powered-by-native'
import { MainLayoutProps } from '../../index'

export const Header: FC<MainLayoutProps> = ({ isInvitePage }) => {
  const { t } = useTranslation(['leave-session', 'common'])

  const navigate = useNavigate()
  const location = useLocation()

  const isAudiencePage = location.pathname.includes(routes.audience)

  const leaveModalProps = useMemo(() => {
    const isSpeakerPage = location.pathname.includes(routes.speaker)
    return {
      heading: t(`${isSpeakerPage ? 'speaker' : 'audience'}.heading`),
      title: t(`${isSpeakerPage ? 'speaker' : 'audience'}.title`),
      actionButtonLabel: t(`${isSpeakerPage ? 'end' : 'leave'}`, { ns: 'common' }),
    }
  }, [location.pathname])

  const { interfaceLanguage, interfaceLanguageOptions, changeInterfaceLanguage } =
    useInterfaceLanguage()

  const { openModal } = useModal()

  const { sessionId } = useParams()
  return (
    <HeaderContainer isAudiencePage={isAudiencePage} isInvitePage={isInvitePage || false}>
      <FlexContainer>
        <Button
          circle
          onClick={() => {
            if (isInvitePage) {
              navigate(`${routes.speaker}/${sessionId}`)
              return
            }
            openModal({
              content: (
                <LeaveSessionModal
                  {...leaveModalProps}
                  onLeaveClick={() => {
                    navigate(routes.entry)
                  }}
                />
              ),
              closeOnClickOutside: true,
            })
          }}
          variant="fainted"
        >
          <ChevronLeftIcon />
        </Button>
      </FlexContainer>
      <FlexContainer>
        <PoweredByNative />
      </FlexContainer>
      <FlexContainerRight isAudiencePage={isAudiencePage}>
        {isAudiencePage && <CaptionsSizePicker />}
        {!isInvitePage && (
          <LanguageSelect
            interfaceLanguage={interfaceLanguage}
            options={interfaceLanguageOptions}
            onClick={changeInterfaceLanguage}
            hiddenLabel={t('selectInterfaceLang')}
            hiddenLabelSelected={t('langSelected')}
            variant="secondary"
          />
        )}
      </FlexContainerRight>
    </HeaderContainer>
  )
}

const FlexContainer = styled(Container)`
  flex: 1 1 0;
  &:nth-of-type(2) {
    flex: 1 1 auto;
    display: none;
  }
  ${mediaQueries.tablet} {
    &:nth-of-type(2) {
      display: block;
      text-align: center;
    }
  }
`

const HeaderContainer = styled.header<{ isAudiencePage: boolean; isInvitePage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, isInvitePage }) =>
    isInvitePage ? theme.colors.transparent : theme.colors.white};
  z-index: 10;
  padding: 0.4rem 1.6rem;
  height: 5.2rem;

  ${mediaQueries.tablet} {
    background: ${({ theme }) => theme.colors.transparent};
    padding: 2rem 1.6rem;
    height: 8.4rem;
  }
  ${mediaQueries.desktop} {
    padding: 2rem 3.2rem;
  }
`
const FlexContainerRight = styled(FlexContainer)<{ isAudiencePage: boolean }>`
  text-align: right;
  ${({ isAudiencePage }) =>
    isAudiencePage &&
    `
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.6rem
    `}
`
