import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DisplayProps } from 'styled-system'
import { Avatar, Container, Heading, NativeChatsIcon } from 'ui'

export const PoweredByNative: FC<DisplayProps> = ({ ...display }) => {
  const { t } = useTranslation('common')

  return (
    <Container display="inline-flex" alignItems="center" justifyContent="center" {...display}>
      <Avatar icon={<NativeChatsIcon />} size="4.4rem" bgColor="white" />
      <Heading variant="h3" as="p" color="greySuperDark" ml="1.2rem">
        {t('poweredByNative')}
      </Heading>
    </Container>
  )
}
