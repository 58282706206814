import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(new LanguageDetector(null, { order: ['querystring', 'cookie', 'localStorage'], caches: [] }))
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['common', 'entry', 'speaker', 'leave-session'],
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {},
  })

export { i18n }
