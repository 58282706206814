import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Container } from 'ui'
import { mediaQueries } from '../../theme/media-queries'

interface CaptionsContainerProps {
  children: ReactNode
}

export const CaptionsContainer: FC<CaptionsContainerProps> = ({ children }) => {
  return <TextContainer>{children}</TextContainer>
}

export const TextContainer = styled(Container)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  border-radius: 0 0 2rem 2rem;
  ${mediaQueries.tablet} {
    border-radius: 2rem;
  }
  ${mediaQueries.desktop} {
    max-width: 65vw;
    margin: 0 auto;
  }
  ::after {
    content: '';
    position: absolute;
    top: -0.2rem;
    left: 0;
    right: 0;
    height: 120px; // adjust this value to control the height of the fade effect
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
`
