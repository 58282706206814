import { useEffect, useState } from 'react'

export const useLoadingState = (isLoading: boolean, data: any, isError: boolean) => {
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    if (!isLoading && (data || isError)) {
      setTimeout(() => setIsLogged(true), 1000) //prevent flickering
    }
  }, [data, isLoading, isError])

  return isLogged
}
