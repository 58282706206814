import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Container, Heading, Text, useModal } from 'ui'

interface LeaveSpeakerRoomProps {
  heading: string
  title: string
  onLeaveClick: VoidFunction
  actionButtonLabel: string
}

export const LeaveSessionModal: FC<LeaveSpeakerRoomProps> = ({
  heading,
  title,
  onLeaveClick,
  actionButtonLabel,
}) => {
  const { t } = useTranslation(['leave-session', 'common'])

  const { closeModal } = useModal()

  return (
    <Container>
      <Container mb="4rem">
        <Heading variant="h2" mb="1.2rem">
          {heading}
        </Heading>
        <Text>{title}</Text>
      </Container>
      <ButtonContainer>
        <Button variant="secondary" width="100%" onClick={closeModal}>
          {t('cancel', { ns: 'common' })}
        </Button>
        <Button
          variant="warning"
          width="100%"
          onClick={() => {
            onLeaveClick()
            closeModal()
          }}
        >
          {actionButtonLabel}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

const ButtonContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
`
