import * as process from 'process'
import React, { FC } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Container, CopyIcon, Heading, Text } from 'ui'
import { routes } from '../../../../shared/navigation/routes'

type SessionIdVariant = 'primary' | 'secondary'
interface SessionIdProps {
  variant?: SessionIdVariant
}

export const SessionId: FC<SessionIdProps> = ({ variant = 'primary' }) => {
  const { t } = useTranslation('speaker')
  const { sessionId } = useParams()

  const showToastMessage = () => {
    toast.success(`${t('copied')}`)
  }

  return (
    <Container display="flex" alignItems="center" justifyContent="center">
      {variant === 'primary' ? (
        <Heading variant="h3" as="p" color="greyDark">
          {t('sessionID')} <SessionIdValue variant={variant}>{sessionId}</SessionIdValue>
        </Heading>
      ) : (
        <Text variant="smallTextRegular" color="greySuperDark">
          {t('sessionID')} <SessionIdValue variant={variant}>{sessionId}</SessionIdValue>
        </Text>
      )}
      <CopyToClipboard
        text={`${process.env.ENV}${routes.audience}/${sessionId}`}
        onCopy={showToastMessage}
      >
        <Button variant="fainted" plain postFix={<CopyIcon />} />
      </CopyToClipboard>
    </Container>
  )
}

const SessionIdValue = styled.span<{ variant: SessionIdVariant }>`
  color: ${({ theme, variant }) =>
    variant === 'primary' ? theme.colors.black : theme.colors.greySuperDark};
`
