import { AudiencePage } from '@pages/audience'
import { EntryPoint } from '@pages/entry-point'
import { InvitePage } from '@pages/invite'
import { SpeakerPage } from '@pages/speaker'
import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { HomeLayout } from '../ui/layouts/home-layout'
import { MainLayout } from '../ui/layouts/main-layout'
import { routes } from './routes'

export const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path={routes.entry} element={<EntryPoint />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path={`${routes.speaker}/:sessionId`} element={<SpeakerPage />} />
          <Route path={`${routes.audience}/:sessionId`} element={<AudiencePage />} />
        </Route>
        <Route element={<MainLayout isInvitePage />}>
          <Route path={`${routes.invite}/:sessionId`} element={<InvitePage />} />
        </Route>
        <Route path="*" element={<Navigate to={routes.entry} />} />
      </Routes>
    </Router>
  )
}
