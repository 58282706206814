import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'ui'
import { Header } from './components/header'

export interface MainLayoutProps {
  isInvitePage?: boolean
}

export const MainLayout: FC<MainLayoutProps> = ({ isInvitePage }) => {
  return (
    <Container height="100%" display="flex" flexDirection="column">
      <Header isInvitePage={isInvitePage} />
      <Container
        height="100%"
        p={{
          _: isInvitePage ? '2rem 1.6rem 1.6rem' : '0 0 1.6rem',
          tablet: '4rem 1.6rem 1.6rem',
        }}
      >
        <Outlet />
      </Container>
    </Container>
  )
}
