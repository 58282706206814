import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Divider } from 'ui'
import { CheckSession } from '../../entities/session/ui/check-session'
import { CreateSessionButton } from '../../entities/session/ui/create-session-button'
import { GLOBAL_CAPTIONS } from '../../shared/lib/constants/app-constants'
import { PageBigTitle } from '../../shared/ui/components/page-big-title'

export const EntryPoint = () => {
  const { t } = useTranslation(['entry', 'common'])

  return (
    <Container>
      <PageBigTitle
        heading={t('heading', { appName: GLOBAL_CAPTIONS })}
        title={t('title')}
        mb="4rem"
      />
      <Container maxWidth="40rem" margin="0 auto">
        <CreateSessionButton />
        <Divider label={t('or', { ns: 'common' })} mb="3.2rem" />
        <CheckSession />
      </Container>
    </Container>
  )
}
