import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'ui'
import { Header } from './components/header'

export const HomeLayout = () => {
  return (
    <Container height="100%" display="flex" flexDirection="column">
      <Header />
      <Container
        height="100%"
        p={{
          _: '8.4rem 1.6rem 1.6rem',
          tablet: '4.8rem 1.6rem 1.6rem',
        }}
      >
        <Outlet />
      </Container>
    </Container>
  )
}
